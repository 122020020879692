<template>
  <div class="products">
    <div class="content">
      <div class="header">
        <h2>Products</h2>
      </div>
      <section class="head">
        <div class="head-bg">
          <div class="text">
            <span class="clarity">Clarity</span>
            <div class="headline">A DAO Tooling Protocol</div>
            <div class="sub-headline">
              Clarity is a powerful DAO tooling protocol built alongside a
              easy-to-use web interface. Create and manage DAOs, establish
              governance rules, and execute votes on-chain, all
              <b>without writing any code. </b>
              <br />
              <br />
              <b
                >Now accepting applicants for our
                <a
                  href="https://cardano.ideascale.com/c/idea/396524"
                  target="blank"
                  >Clarity DAO Incubator</a
                >.</b
              >
            </div>
            <a href="https://forms.gle/WnWvPNEisH8qAaHM8" target="blank"
              ><button class="whiteButton marginTop">Apply</button></a
            ><br />
            <a href="https://discord.gg/SUWpTtsJaA" target="_blank">
              <button class="blueButton marginTop">Join Discord</button></a
            >
          </div>
        </div>
        <div class="coin">
          <img :src="images.coin" />
        </div>
      </section>
      <div class="product white">
        <div class="header">
          <h3 class="title">CC Bot</h3>
          <div class="picture">
            <img :src="images.ccBot" />
          </div>
          <h4 class="subtitle">Discord management</h4>
        </div>
        <div class="details">
          CC Bot helps Cardano communities manage roles in their Discord
          Servers. Grant roles to users who hold a specific Cardano native asset
          or delegate to your stake pool.
          <div class="buttonContainer">
            <a
              href="https://docs.google.com/document/d/1xyyx2LlmeY5NUWDrnqlsk6LThqRdfFi2sHOexZaPDzc/edit?usp=sharing"
              target="blank"
              ><button class="actionButton">Add CC Bot</button></a
            >
          </div>
        </div>
      </div>
      <div class="product">
        <div class="header">
          <h3 class="title">NFT Minting Service</h3>
          <div class="picture">
            <img :src="images.nft" />
          </div>
          <h4 class="subtitle">From layers to mint site</h4>
        </div>
        <div class="details">
          Use our CNFT generation bot to randomize layered artwork based on a
          pre-determined rarity chart. Upload CNFT's to the Cardano blockchain
          to mint from any website.
        </div>
      </div>

      <div class="white product">
        <div class="header">
          <h3 class="title">Escrow Service</h3>
          <div class="picture">
            <img :src="images.mascot" />
          </div>
          <h4 class="subtitle">
            A secure platform for executing agreements on-chain
          </h4>
        </div>
        <div class="details">
          Create and manage escrow agreements on Cardano using manual user input
          to verify completion of agreements. Assign an arbitrator to fairly
          resolve disputes on-chain. Use this tool to securely transfer Cardano
          native assets.
        </div>
      </div>

      <div class="contact">
        <div class="headline">
          <h4>Building on Cardano?</h4>
        </div>
        <a href="https://discord.gg/SUWpTtsJaA" target="_blank">
          <button class="actionButton">Join Discord</button></a
        >
        <div class="text">
          <p>
            If you're interested in being one of the first DAO communities in
            the Cardano ecosystem to operate on-chain, come chat with us!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: {
        mascot: require("@/assets/icons/escrowNew.png"),
        ccBot: require("@/assets/Logos/discord.png"),
        nft: require("@/assets/icons/nftNew.png"),
        bullet: require("@/assets/bullet.png"),
        clarity: require("@/assets/clarityCoin.png"),
        coin: require("@/assets/coin.gif"),
      },
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.products {
  margin-top: 80px;

  .content {
    padding: 5%;
    width: 70%;
    margin: 10%;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgb(255, 255, 255, 0.4)),
      to(rgba(255, 255, 255, 0.1))
    );
    background-attachment: scroll;
    border-radius: 50px;
    border: 1px solid white;

    a {
      color: #636eff;
    }

    h2 {
      font-size: 4rem;
      color: #636eff;
      text-align: center;

      @media screen and (max-width: 400px) {
        font-size: 3.5rem;
      }
    }

    .underline {
      border-bottom: 3px solid #636eff;
      width: 40%;
      margin-right: auto;
      margin-left: auto;
      margin-top: 30px;
    }

    .actionButton {
      padding: 10px 20px;
      margin: 30px 0px;
      background-color: #636eff;
      color: white;
      font-size: 1.4rem;
      border: none;
      transition: 0.7s;
      border-radius: 10px;
      &:hover {
        background-color: #636eff;
        cursor: pointer;
      }
    }

    .buttonContainer {
      text-align: center;
    }

    .contact {
      text-align: center;
      margin-top: 80px;
      font-size: 1.5rem;
    }

    .white {
      background-color: rgba(255, 255, 255, 0.5);

      // resize to fit full container width
      margin: 0 -7.25%;
      padding: 0 7.25%;
    }

    h3 {
      font-size: 3rem;
      color: #636eff;
    }

    h4 {
      font-size: 1.5rem;
    }

    .head {
      margin-top: 100px;
      position: relative;
      padding-bottom: 120px;

      .head-bg {
        position: static;
        top: 0;

        width: 100%;
        transform-origin: left;
        padding-bottom: 320px;

        @media screen and (max-width: 820px) {
          padding-bottom: 0;
        }

        .text {
          transform-origin: left;
          padding-top: var(--navbarHeight);
          padding-left: 4%;
          height: 100%;
          @media screen and (max-width: 820px) {
            width: 90%;
            margin: auto;
          }

          color: #636eff;

          .clarity {
            font-weight: bolder;
            font-size: 8rem;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: #636eff;
            color: transparent;
            font-family: Avenir, Helvetica, Arial, sans-serif;
            @media screen and (max-width: 560px) {
              font-size: 4rem;
              margin-right: initial;
            }
          }
          .headline {
            margin-right: 44%;
            font-size: 2rem;
            // color: white;
            // font-weight: 520;
            font-weight: bold;
            @media screen and (max-width: 820px) {
              font-size: 2rem;
              margin-right: initial;
            }
          }
          .sub-headline {
            margin-top: 3%;
            margin-right: 54%;
            font-size: 1.8rem;
            font-weight: 450;
            // text-align: right;
            @media screen and (max-width: 820px) {
              font-size: 1.5rem;
              margin-right: initial;
            }

            @media screen and (max-width: 420px) {
              font-size: 1.3rem;
              margin-right: initial;
            }
          }

          .marginTop {
            margin-top: 40px !important;
          }
        }
      }
      .coin {
        position: absolute;
        right: 120px;
        top: 300px;
        img {
          max-width: 600px;
        }

        @media screen and (max-width: 1600px) {
          right: 100px;
          top: 350px;
          img {
            max-width: 500px;
          }
        }

        @media screen and (max-width: 1400px) {
          right: 0px;
          top: 400px;
          img {
            max-width: 450px;
          }
        }

        @media screen and (max-width: 1100px) {
          right: 0px;
          top: 500px;
          img {
            max-width: 400px;
          }
        }

        @media screen and (max-width: 900px) {
          position: static;
          width: 100%;
          text-align: center;
          margin-top: -200px;
          margin-bottom: -200px;
          img {
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        @media screen and (max-width: 820px) {
          margin-top: 100px;
          img {
            max-width: 400px;
          }
        }

        @media screen and (max-width: 700px) {
          img {
            max-width: 350px;
          }
        }

        @media screen and (max-width: 600px) {
          img {
            max-width: 300px;
          }
        }

        @media screen and (max-width: 500px) {
          img {
            max-width: 250px;
          }
        }
      }
    }

    .blueButton {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #636eff;
      color: white;
      font-size: 1.4rem;
      border: none;
      transition: 0.7s;
      border-radius: 10px;
      &:hover {
        background-color: hsl(236, 56%, 56%);
        cursor: pointer;
      }
    }

    .whiteButton {
      z-index: 1;

      padding: 10px 20px;

      background-color: rgb(255, 255, 255, 0.4);
      color: #636eff;
      font-size: 1.4rem;
      border: 1px solid white;
      transition: 0.7s;
      border-radius: 10px;

      &:hover {
        background-color: rgb(255, 255, 255, 0.6);
        color: #636eff;
        cursor: pointer;
      }

      a {
        text-decoration: none;
      }

      a:visited {
        color: #636eff;
      }

      // @media screen and (max-width: 620px) {
      //   display: none;
      // }
    }

    .product {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      grid-gap: 1rem;
      text-align: center;
      margin-top: 5%;
      font-size: 1.8rem;

      padding-top: 10%;
      padding-bottom: 10%;

      .picture {
        img {
          height: 120px;
        }

        margin: 20px 0;
      }

      .details {
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
      }

      @media screen and (max-width: 820px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 420px) {
        font-size: 1.3rem;
      }
    }
  }
}
</style>