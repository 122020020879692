<template>
  <div class="coming-soon">
    <div class="content">
      <h2>Coming soon!</h2>
      <p>
        Please <router-link to="/contact">contact us</router-link> with any
        specific questions!
      </p>
      <button class="back-btn" @click="$router.push('/')">Home</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      images: {},
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>


<style lang="scss" scoped>
p {
  padding-top: 10px;
  font-size: 1.5rem;
  color: black;
  font-weight: normal;
}
h4 {
  font-size: 2rem;
  text-align: center;
  color: #636eff;
}

.coming-soon {
  height: 100vh;
  padding-top: 80px;
  width: 80%;
  margin-left: 10%;
  position: relative;
  overflow: hidden;

  .app-btn {
    text-decoration: none;
  }

  .buttons {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .title {
    width: 80%;
    font-size: 4em;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .subtitle {
    width: 80%;
    font-size: 1.5em;
    color: white;
    margin-bottom: 50px;
  }

  .welcomeButton {
    z-index: 1;
    margin-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgb(255, 255, 255, 0.4);
    color: white;
    font-size: 1.4rem;
    border: 1px solid white;
    transition: 0.7s;
    border-radius: 10px;

    &:hover {
      background-color: rgb(255, 255, 255, 0.6);
      color: #636eff;
      cursor: pointer;
    }
  }
}

.content {
  animation: slideOutDown 0.8s ease-in-out forwards 0s;
  --navbarHeight: 80px; //80px is the height of navbar

  position: relative;
  width: 70%;
  padding: 5% 10% 5% 10%;

  overflow: hidden;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;
  margin-top: 160px;

  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255, 0.6)),
    to(rgba(255, 255, 255, 0.3))
  );
  background-attachment: scroll;
  border-radius: 50px;
  border: 1px solid white;

  h2 {
    font-size: 3em;
    color: #636eff;
  }

  .head {
    position: relative;
    padding-bottom: 120px;

    .head-bg {
      position: static;
      top: 0;

      width: 100%;
      transform-origin: left;
      padding-bottom: 320px;
      .text {
        transform-origin: left;
        padding-top: var(--navbarHeight);
        padding-left: 4%;
        height: 100%;
        @media screen and (max-width: 820px) {
          width: 90%;
          margin: auto;
        }
        color: #636eff;
        .headline {
          margin-top: 3%;
          margin-right: 44%;
          font-size: 3rem;
          // color: white;
          // font-weight: 520;
          font-weight: bold;
          @media screen and (max-width: 820px) {
            font-size: 2rem;
            margin-right: initial;
          }
        }
        .sub-headline {
          margin-top: 3%;
          margin-right: 54%;
          font-size: 1.8rem;
          font-weight: 450;
          // text-align: right;
          @media screen and (max-width: 820px) {
            font-size: 1.5rem;
            margin-right: initial;
          }
        }
      }
    }
  }
  .back-btn {
    margin-top: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #636eff;
    color: white;
    font-size: 1.4rem;
    border: none;
    transition: 0.7s;
    border-radius: 10px;
    &:hover {
      background-color: #636eff;
      cursor: pointer;
    }
  }
}

// section:nth-of-type(2n){
//   background-color: lime;
// }
</style>
