<template>
  <div class="home">
    <div class="welcome">
      <div class="buttons">
        <div class="title">
          We create blockchain technology that everyone can use.
        </div>
        <div class="subtitle">
          At Clear Contracts, we lower the barrier to entry to build and
          interact on the Cardano blockchain.
        </div>
        <a href="https://discord.gg/SUWpTtsJaA" target="_blank">
          <button class="joinDiscordButton">Join Discord</button></a
        >
      </div>
    </div>
    <div class="content">
      <section class="head">
        <div class="head-bg">
          <div class="text">
            <span class="clarity">Clarity</span>
            <div class="headline">A DAO Tooling Protocol</div>
            <div class="sub-headline">
              We are excited to bring Clarity, a DAO tooling protocol, to
              Cardano. Easily create, manage, and organize DAOs, coming 2022.
            </div>
            <a href="https://discord.gg/SUWpTtsJaA" target="_blank">
              <button class="joinDiscordButton marginTop">
                Join Discord
              </button></a
            >
          </div>
        </div>
        <div class="coin">
          <img :src="images.coin" />
        </div>
      </section>
      <section class="differentiation">
        <div class="container">
          <div class="icon-container">
            <img :src="images.voting" />
          </div>

          <h4>DAO Management</h4>
          <p>
            Easily form and manage DAOs. Access a community-led smart contract
            library to execute specific transactions, facilitate voting, and
            manage proposals.
          </p>
        </div>
        <div class="container">
          <div class="icon-container">
            <img :src="images.dao" />
          </div>

          <h4>NFT Communities</h4>
          <p>
            Provide exclusive access to special content, voting, and more to NFT
            holders. Easily create utility for your Cardano NFT project.
          </p>
        </div>
        <div class="container">
          <div class="icon-container">
            <img :src="images.escrow" />
          </div>

          <h4>Escrow Services</h4>
          <p>
            DAOs, individuals, and businesses can utilize our escrow service.
            Easily enter into secure agreements by filling out a simple online
            form.
          </p>
        </div>
      </section>
      <section class="automate">
        <div class="wrapper">
          <br />
          <h4>
            We spend the time and money to audit our smart contract templates so
            you don't have to.
          </h4>
          <br />
          <a href="https://discord.gg/SUWpTtsJaA" target="_blank">
            <button class="contactButton marginTop">Join Discord</button></a
          >
        </div>
      </section>
      <section class="useCase">
        <div class="container">
          <div class="icon-container">
            <img :src="images.screen" />
          </div>

          <h4>Easy to use interface</h4>
          <p>
            Instead of complicated Plutus programming, simply fill out basic
            forms to create and deploy custom Cardano smart contracts.
          </p>
        </div>
        <div class="container">
          <div class="icon-container">
            <img :src="images.world" />
          </div>

          <h4>Streamlined Web3 development</h4>
          <p>
            We provide audited templates to versatile smart contracts that are
            the backbone needed to create decentralized applications and
            communities on the Cardano blockchain.
          </p>
        </div>
        <div class="container">
          <div class="icon-container">
            <img :src="images.link" />
          </div>

          <h4>Cardano focused</h4>
          <p>
            We are built atop the Cardano blockchain to ensure reliable record
            keeping and security.
          </p>
        </div>
      </section>
      <section class="partners">
        <div class="wrapper">
          <h4>Our Partners</h4>
          <div class="grid">
            <!-- <div class="mlabs"> -->
            <a
              href="https://mlabs.city/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="images.mlabs" />
            </a>
            <!-- </div> -->
            <a
              href="https://www.f10.global/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img :src="images.f10" />
            </a>
          </div>
          <a href="https://discord.gg/SUWpTtsJaA" target="_blank">
            <button class="contactButton marginTop">Join Discord</button></a
          >
        </div>
      </section>
      <section class="articles">
        <h4>See why smart contracts are the future</h4>
        <br />
        <div class="logos">
          <a
            href="https://www.accountingtoday.com/news/deloitte-to-accelerate-blockchain-development-in-us-and-canada"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.accountingtoday" />
          </a>
          <a
            href="https://www.bloomberg.com/press-releases/2021-07-01/blockchain-technology-gains-further-adoption-from-supply-chain-to-financial-solutions-and-mining"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.bloomberg" />
          </a>
          <a
            href="https://www.economist.com/technology-quarterly/2018/08/30/the-promise-of-the-blockchain-technology"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.economist" />
          </a>
          <a
            href="https://www.forbes.com/sites/forbesfinancecouncil/2021/01/07/how-smart-contracts-bring-real-world-improvements-to-post-trade-settlement/?sh=764798d02289"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.forbes" />
          </a>
        </div>
        <br />
        <div class="logos">
          <a
            href="https://www.ibm.com/topics/smart-contracts"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.ibm" />
          </a>
          <a
            href="https://www.investopedia.com/news/are-smart-contracts-best-blockchain/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.investopedia" />
          </a>
          <a
            href="https://www.marketwatch.com/press-release/blockchain-technology-market-to-see-phenomenal-growth-during-2021-to-2027-2021-07-23?siteid=bigcharts&dist=bigcharts&tesla=y"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.marketwatch" />
          </a>
          <a
            href="https://deloitte.wsj.com/articles/getting-smart-about-smart-contracts-1466654488"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="images.deloitte" />
          </a>
        </div>
      </section>
      <section class="contact">
        <div class="wrapper">
          <div class="headline">
            <h4>Now accepting Clarity DAO Incubator applications</h4>
          </div>
          <br />
          <a href="https://forms.gle/WnWvPNEisH8qAaHM8" target="blank"
            ><button class="contactButton">Apply Now</button></a
          >
          <div class="text">
            <br />
            <p>
              If you want to create a DAO on Cardano with operations fully
              on-chain, apply to our incubator. Selected projects will work
              collaboratively to build and grow their communities.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      images: {
        accountingtoday: require("@/assets/Logos/accountingtoday.png"),
        bloomberg: require("@/assets/Logos/bloomberg.png"),
        economist: require("@/assets/Logos/economist.png"),
        forbes: require("@/assets/Logos/forbes.png"),
        ibm: require("@/assets/Logos/ibm.png"),
        investopedia: require("@/assets/Logos/investopedia.png"),
        marketwatch: require("@/assets/Logos/marketwatch.png"),
        deloitte: require("@/assets/Logos/deloitte.png"),
        mlabs: require("@/assets/Logos/mlabs.png"),
        f10: require("@/assets/Logos/f10.png"),
        coin: require("@/assets/coin.gif"),

        voting: require("@/assets/icons/daoNew.png"),
        dao: require("@/assets/icons/nftNew.png"),
        escrow: require("@/assets/icons/escrowNew.png"),

        screen: require("@/assets/icons/interfaceNew.png"),
        world: require("@/assets/icons/keyboard.png"),
        link: require("@/assets/icons/target.png"),
      },
      beforeRouteUpdate(to, from, next) {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        this.transitionName =
          toDepth < fromDepth ? "slide-right" : "slide-left";
        next();
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.main();
  },
};
</script>
<style lang="scss" scoped>
p {
  padding-top: 10px;
  font-size: 1.5rem;
  color: black;
  font-weight: normal;
}
h4 {
  font-size: 2rem;
  text-align: center;
  color: #636eff;
}

.contactButton {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #636eff;
  color: white;
  font-size: 1.4rem;
  border: none;
  transition: 0.7s;
  border-radius: 10px;
  &:hover {
    background-color: hsl(236, 56%, 56%);
    cursor: pointer;
  }
}

.joinDiscordButton {
  z-index: 1;

  padding: 10px 20px;

  background-color: rgb(255, 255, 255, 0.4);
  color: #636eff;
  font-size: 1.4rem;
  border: 1px solid white;
  transition: 0.7s;
  border-radius: 10px;

  &:hover {
    background-color: rgb(255, 255, 255, 0.6);
    color: #636eff;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  a:visited {
    color: #636eff;
  }

  // @media screen and (max-width: 620px) {
  //   display: none;
  // }
}

.welcome {
  margin-top: 40px;
  height: calc(120vh - 80px);
  width: 80%;
  margin-left: 10%;
  position: relative;
  vertical-align: middle;

  animation: slideInDown 0.8s ease-in-out forwards 0s;

  @media screen and (min-height: 820px) {
    margin-top: 100px;
  }

  .buttons {
    margin: 0;
    position: absolute;
    top: 25%;
    // left: 50%;
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
  }
  .title {
    font-size: 90px;
    color: #636eff;
    font-weight: bold;
    margin-bottom: 20px;
    overflow-wrap: break-word;
    width: 80%;

    @media screen and (max-width: 820px) {
      width: 90%;
      font-size: 45px;
    }
  }

  .subtitle {
    width: 80%;
    font-size: 1.5em;
    color: #000000;
    margin-bottom: 50px;
  }
}

.content {
  --navbarHeight: 80px; //80px is the height of navbar

  // position: relative;

  width: 80%;
  margin-left: 10%;
  margin-bottom: 10%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255, 0.4)),
    to(rgba(255, 255, 255, 0.1))
  );
  background-attachment: scroll;
  border-radius: 50px;
  border: 1px solid white;

  margin-top: (160px);

  .head {
    position: relative;
    padding-bottom: 120px;

    .head-bg {
      position: static;
      top: 0;

      width: 100%;
      transform-origin: left;
      padding-bottom: 320px;

      @media screen and (max-width: 820px) {
        padding-bottom: 0;
      }

      .text {
        transform-origin: left;
        padding-top: var(--navbarHeight);
        padding-left: 4%;
        height: 100%;
        @media screen and (max-width: 820px) {
          width: 90%;
          margin: auto;
        }

        color: #636eff;

        .clarity {
          font-weight: bolder;
          font-size: 8rem;
          -webkit-text-stroke-width: 2px;
          -webkit-text-stroke-color: #636eff;
          color: transparent;
          font-family: Avenir, Helvetica, Arial, sans-serif;
          @media screen and (max-width: 560px) {
            font-size: 4rem;
            margin-right: initial;
          }
        }
        .headline {
          margin-right: 44%;
          font-size: 2rem;
          // color: white;
          // font-weight: 520;
          font-weight: bold;
          @media screen and (max-width: 820px) {
            font-size: 2rem;
            margin-right: initial;
          }
        }
        .sub-headline {
          margin-top: 3%;
          margin-right: 54%;
          font-size: 1.8rem;
          font-weight: 450;
          // text-align: right;
          @media screen and (max-width: 820px) {
            font-size: 1.5rem;
            margin-right: initial;
          }

          @media screen and (max-width: 420px) {
            font-size: 1.3rem;
            margin-right: initial;
          }
        }

        .marginTop {
          margin-top: 40px !important;
        }
      }
    }
    .coin {
      position: absolute;
      right: 120px;
      top: 300px;
      img {
        max-width: 600px;
      }

      @media screen and (max-width: 1600px) {
        right: 100px;
        top: 350px;
        img {
          max-width: 500px;
        }
      }

      @media screen and (max-width: 1400px) {
        right: 80px;
        top: 400px;
        img {
          max-width: 450px;
        }
      }

      @media screen and (max-width: 1100px) {
        right: 80px;
        top: 500px;
        img {
          max-width: 400px;
        }
      }

      @media screen and (max-width: 900px) {
        position: static;
        width: 100%;
        text-align: center;
        margin-top: -200px;
        margin-bottom: -200px;
        img {
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      @media screen and (max-width: 820px) {
        margin-top: 100px;
        img {
          max-width: 400px;
        }
      }

      @media screen and (max-width: 700px) {
        img {
          max-width: 350px;
        }
      }

      @media screen and (max-width: 600px) {
        img {
          max-width: 300px;
        }
      }

      @media screen and (max-width: 500px) {
        img {
          max-width: 250px;
        }
      }
    }
  }

  .automate {
    background-color: rgba(255, 255, 255, 0.5);
    .wrapper {
      text-align: center;

      padding-top: 60px;
      padding-bottom: 60px;
      width: 71%;
      margin: auto;
      @media screen and (max-width: 780px) {
        width: 82%;
      }
    }
  }

  .partners {
    background-color: rgba(255, 255, 255, 0.5);
    .wrapper {
      text-align: center;
      padding: 2%;
      margin-left: auto;
      margin-right: auto;

      .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 1rem;

        a {
          max-width: 100%;

          img {
            max-width: 80%;
            margin-left: 0;
          }

          .mlabs {
            grid-area: "mlabs";
            text-align: center;

            &:hover {
              cursor: pointer;
            }
          }

          .f10 {
            grid-area: "f10";
            text-align: center;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      @media screen and (max-width: 780px) {
        width: 82%;
      }
    }
  }

  .articles {
    padding-top: 60px;
    padding-bottom: 120px;
    width: 71%;
    margin: auto;
    .logos {
      display: flex;
      flex-direction: row;

      a {
        display: inline-block;
        margin: 3%;
        img {
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 780px) {
      width: 82%;
    }
  }

  .contact {
    border-radius: 0 0 50px 50px;
    background-color: rgba(255, 255, 255, 0.5);
    .wrapper {
      padding-top: 60px;
      padding-bottom: 60px;
      width: 71%;
      margin: auto;
      text-align: center;
      @media screen and (max-width: 780px) {
        width: 82%;
      }
    }
  }

  .useCase,
  .differentiation {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

    padding-left: 10%;
    padding-right: 10%;
    padding-top: 60px;
    padding-bottom: 60px;
    .container {
      flex: 1 1 0px;
      padding: 1%;
      text-align: center;

      .icon-container {
        height: 100px;
        margin-bottom: 30px !important;
      }

      img {
        width: 100px;
      }
      @media screen and (max-width: 1200px) {
        flex: initial;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-direction: column;
    }
  }
}

// section:nth-of-type(2n){
//   background-color: lime;
// }
</style>
