import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import Contact from '../views/Contact.vue'
import Blog from '../views/Blog.vue'
import Coming_Soon from '../views/Coming-Soon.vue'
import Contribute from '../views/Contribute.vue'
import Team from '../views/Team.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'slide-right' },
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/coming-soon',
    name: 'Coming Soon',
    component: Coming_Soon,
  },
  {
    path: '/contribute',
    name: 'Contribute',
    component: Contribute,
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
