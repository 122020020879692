<template>
  <div id="nav">
    <ul class="links">
      <router-link id="imgButton" to="/"
        ><img :src="images.logo"
      /></router-link>
      <li><router-link to="/contact">Contact Us</router-link></li>
      <li><router-link to="/products">Products</router-link></li>
      <li><router-link to="/">Home</router-link></li>
    </ul>
    <div v-if="!show">
      <button v-on:click="show = !show" class="hamburger">
        <i class="fa fa-bars"></i>
      </button>
    </div>
    <transition name="dropdown">
      <div class="dropdown-content" v-if="show">
        <button v-on:click="show = !show" class="exit">
          <i class="fa fa-times"></i>
        </button>
        <div class="buffer"></div>
        <li v-on:click="show = !show">
          <router-link to="/">Home</router-link>
        </li>
        <li v-on:click="show = !show">
          <router-link to="/products">Products</router-link>
        </li>
        <li v-on:click="show = !show">
          <router-link to="/contact">Contact Us</router-link>
        </li>
      </div>
    </transition>
    <div v-if="show">
      <div class="escape" v-on:click="show = !show"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      images: {
        logo: require("@/assets/cc-logo-long.png"),
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.expanded {
  background-color: rgba(1, 41, 191, 0.6);
}
#nav {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 80px;
  overflow: visible;
  margin-bottom: 0px;

  button {
    border: none;
    background: none;

    &:hover {
      cursor: pointer;
    }
  }

  #imgButton {
    display: inline-block;
    margin-left: 40px;
    img {
      max-height: 40px;
      width: auto;
      padding-top: 19px;
    }

    @media screen and (max-width: 820px) {
      margin-left: 20px;
    }
  }

  .links {
    margin-right: 40px;
  }

  ul {
    list-style-type: none;

    li {
      float: right;

      @media (max-width: 820px) {
        display: none;
      }

      a {
        display: block;
        text-align: center;
        padding-left: 40px;
        padding-top: 23.5px;
        padding-bottom: 23.5px;

        font-size: 1.5rem;
        text-decoration: none;
        color: #636eff;
        font-weight: bold;
        transition: 0.3s;
        &:hover {
          color: #00059f;
        }
      }
    }
  }

  .hamburger {
    position: fixed;
    right: 20px;
    top: 20px;
    color: #636eff;
    font-size: 32px;

    @media (min-width: 820px) {
      display: none;
    }
  }

  .exit {
    position: fixed;
    right: 20px;
    top: 20px;
    color: white;
    font-size: 32px;
  }

  .dropdown-content {
    background-color: rgba(1, 41, 191, 0.6);
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;

    li {
      list-style-type: none;
      text-align: center;
      font-size: 2em;

      &:hover {
        background-color: rgba(1, 41, 191, 0.8);
        transition-duration: 0.5s;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: white;

        &:visited {
          color: white;
        }
      }
    }

    .buffer {
      height: 80px;
      width: 100%;
    }

    @media (min-width: 820px) {
      display: none;
    }
  }

  .escape {
    position: relative;
    height: 1000px;
    width: 100%;
  }
}
button.blog {
  background-color: transparent;
  border: none;
}

.dropdown-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.dropdown-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.dropdown-enter-from, .dropdown-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translatey(-250px);
}
</style>