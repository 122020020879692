<template>
  <div class="footer">
    <div class="container">
      <div class="child logo">
        <router-link id="imgButton" to="/"
          ><img :src="images.logo"
        /></router-link>
      </div>
      <div class="child">
        <h3>Clear Contracts</h3>
        <ul>
          <li><router-link to="/team">Team</router-link></li>
          <li><router-link to="/contact">Contact Us</router-link></li>
          <li><router-link to="/blog">Blog</router-link></li>
          <li class="linkContainer">
            <a href="https://twitter.com/ClearContracts" target="blank"
              ><img class="socialIcon" :src="images.twitter"
            /></a>
          </li>
          <li class="linkContainer">
            <a href="https://discord.gg/JYV5wSx6kB" target="blank"
              ><img class="socialIcon" :src="images.discord"
            /></a>
          </li>
        </ul>
      </div>
      <div class="child">
        <h3>Resources</h3>
        <ul>
          <li>
            <a href="https://docsend.com/view/897694qig7qux37e" target="_blank"
              >Clarity Protocol Whitepaper</a
            >
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/folders/1eCa1Wr1v5mtm9sUU9rVJE3PA3RBzcGBj?usp=sharing"
              target="_blank"
              >Media Kit</a
            >
          </li>
          <li><router-link to="/contribute">Contribute</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: {
        logo: require("@/assets/cc-logo-long.png"),
        discord: require("@/assets/Logos/discord.png"),
        twitter: require("@/assets/Logos/twitter.png"),
      },
    };
  },
};
</script>

<style lang="scss">
.footer {
  width: 100%;
  font-size: 1.2rem;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255, 0.6)),
    to(rgba(255, 255, 255, 0.3))
  );
  h3 {
    color: #636eff;
  }

  .container {
    display: inline-flex;
  }

  .child {
    width: 33.33vw;
    padding: 20px 0 0 15px;
  }

  ul {
    list-style-type: none;

    li {
      margin-top: 15px;
    }

    a {
      text-decoration: none;
      color: black;
    }

    a:visited {
      color: black;
    }
  }
  .logo {
    img {
      padding-left: 4%;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 30px;
    }

    @media screen and (max-width: 820px) {
      display: none;
    }
  }

  .socialIcon {
    height: 25px;
    padding-right: 15px;
  }

  .linkContainer {
    display: inline-block;
  }
}
</style>