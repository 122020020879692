<template>
  <div class="contact">
    <div class="menu-bg"></div>
    <div class="form-wrapper">
      <div class="done" v-if="done">
        Your contact request has been sent successfully! We will get back to you
        shortly.
      </div>
      <div class="submitError" v-if="submitError !== ''">
        Sorry your request could not be completed for the reason below:
        <br />
        {{ submitError }}
        <br />
        Please try again later
      </div>

      <form
        class="contactForm"
        v-if="submitError === '' && done !== true"
        @submit.prevent="submitForm"
      >
        <div class="header">
          <h2>Contact</h2>
        </div>
        <label class="input-label" for="input_option">I'd like to...</label>
        <select v-model="option" id="input_option">
          <option></option>
          <option>Talk to sales</option>
          <option>Inquire about partnerships</option>
          <option>Talk about joining our team</option>
          <option>Ask a product question</option>
          <option>Integrate a custom smart contract into my business</option>
        </select>
        <div class="error" v-if="option === '' && errors">
          This field is required
        </div>

        <label class="input-label" for="input_first_name">First Name</label>
        <input v-model="first_name" id="input_first_name" />
        <div class="error" v-if="first_name === '' && errors">
          This field is required
        </div>

        <label class="input-label" for="input_last_name">Last Name</label>
        <input v-model="last_name" id="input_last_name" />
        <div class="error" v-if="last_name === '' && errors">
          This field is required
        </div>

        <label class="input-label" for="input_job_title">Job Title</label>
        <input v-model="job_title" id="input_job_title" />

        <label class="input-label" for="input_company">Company</label>
        <input v-model="company" id="input_company" />

        <label class="input-label" for="input_email">Email</label>
        <input v-model="email" id="input_email" />
        <div class="error" v-if="email === '' && errors">
          This field is required
        </div>
        <div class="error" v-if="emailError && errors && email !== ''">
          Please enter a valid email address
        </div>

        <label class="input-label" for="input_phone">Phone Number</label>
        <input v-model="phone" id="input_phone" />

        <label class="input-label" for="input_content"
          >How can we help you?</label
        >
        <textarea v-model="content" id="input_content"></textarea>
        <div class="error" v-if="content === '' && errors">
          This field is required
        </div>

        <button id="submitButton" type="submit">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      option: "",
      first_name: "",
      last_name: "",
      job_title: "",
      company: "",
      email: "",
      phone: "",
      content: "",
      errors: false,
      emailError: false,
      submitError: "",
      done: false,
    };
  },
  methods: {
    async submitForm() {
      this.validateForm();
      if (this.errors) {
        // console.log(this.errors)
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          option: this.option,
          first_name: this.first_name,
          last_name: this.last_name,
          job_title: this.job_title,
          company: this.company,
          email: this.email,
          phone: this.phone,
          content: this.content,
        }),
      };
      fetch("https://formcarry.com/s/idjaRfXQy2E", requestOptions)
        .then(async (response) => {
          const data = await response;
          // console.log(data);
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          this.done = true;
        })
        .catch((error) => {
          this.submitError = error;
        });
    },
    validateForm() {
      this.errors = false;
      this.emailError = false;
      if (this.option === "") {
        this.errors = true;
      }
      if (this.first_name === "") {
        this.errors = true;
      }
      if (this.last_name === "") {
        this.errors = true;
      }
      if (this.email === "") {
        this.errors = true;
      }
      if (!this.validateEmail(this.email)) {
        this.errors = true;
        this.emailError = true;
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.menu-bg {
  --navbarHeight: 80px;
  height: var(--navbarHeight);
  width: 100%;
}

h2 {
  font-size: 4rem;
  color: #636eff;
  text-align: center;

  @media screen and (max-width: 400px) {
    font-size: 3.5rem;
  }
}

.header {
  margin-bottom: 100px;
}

.form-wrapper {
  form {
    --navbarHeight: 80px; //80px is the height of navbar

    position: relative;
    padding: 10%;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgb(255, 255, 255, 0.6)),
      to(rgba(255, 255, 255, 0.3))
    );
    background-attachment: scroll;
    border-radius: 50px;
    border: 1px solid white;
  }

  .done,
  .submitError {
    font-size: 2.5rem;
    color: #1a3496;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1a3496;
    margin-top: 80px;
    margin-bottom: 20px;
  }
  width: 50%;
  margin: auto;
  @media screen and (max-width: 750px) {
    width: 90%;
  }
  .contactForm {
    display: flex;
    flex-direction: column;
    width: 80%;
    input,
    select,
    textarea {
      padding: 12px 20px;
      margin: 15px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 1.2rem;
    }
    textarea {
      // width: 100%;
      resize: vertical;
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    label {
      font-size: 1.2rem;
      color: black;
    }
    #submitButton {
      background-color: #636eff;
      color: white;
      padding: 14px 20px;
      margin: 8px 0;
      font-size: 1.4rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 20px;
    }
    .error {
      color: darkred;
    }
  }
}
</style>