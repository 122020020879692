<template>
  <section class="section team" id="team">
    <div class="our-team">
      <h2>Our Team</h2>
      <div class="content">
        <div class="members">
          <div v-for="member in team" :key="member.name" class="member">
            <img :src="member.pic" />
            <div class="name">{{ member.name }}</div>
            <div class="title">{{ member.title }}</div>
            <a :href="member.linkedin" target="_blank" class="linkedin">
              <i class="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="our-advisors">
      <h2>Our Advisors</h2>
      <div class="content">
        <div class="members">
          <div v-for="member in advisors" :key="member.name" class="member">
            <img :src="member.pic" />
            <div class="name">{{ member.name }}</div>
            <div class="title">{{ member.title }}</div>
            <a :href="member.linkedin" target="_blank" class="linkedin">
              <i class="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {
      images: {},
      team: [
        {
          name: "Logan Panchot",
          title: "Chief Executive Officer",
          linkedin: "https://www.linkedin.com/in/logan-panchot/",
          pic: require("@/assets/Team/logan.png"),
        },
        {
          name: "Justin Schreiner",
          title: "Chief Product Officer",
          linkedin: "https://www.linkedin.com/in/justinschreiner/",
          pic: require("@/assets/Team/justin.png"),
        },
        // {
        //   name: "Beau Hodes",
        //   title: "Lead Backend Engineer",
        //   linkedin: "https://www.linkedin.com/in/beauhodes/",
        //   pic: require("@/assets/Team/beau.jpeg"),
        // },
        // {
        //   name: "Kyle McGregor",
        //   title: "Full Stack Engineer",
        //   linkedin: "https://www.linkedin.com/in/klmcgregor/",
        //   pic: require("@/assets/Team/kyle.png"),
        // },
        {
          name: "Nathan Jang",
          title: "Frontend Engineer",
          linkedin: "https://www.linkedin.com/in/nathan-sb-j/",
          pic: require("@/assets/Team/nathan.jpeg"),
        },
      ],
      advisors: [
        {
          name: "Shannon Wu",
          title: "MakerDAO & Bloom Protocol Founding Team",
          linkedin: "https://www.linkedin.com/in/shannonwu/",
          pic: require("@/assets/Advisors/shannon.png"),
        },
        {
          name: "Hannah Sieber",
          title: "EcoFlow CEO",
          linkedin: "https://www.linkedin.com/in/hannah-sieber/",
          pic: require("@/assets/Advisors/hannah.png"),
        },
        {
          name: "Luke deWilde",
          title: "49ers Enterprises Associate",
          linkedin: "https://www.linkedin.com/in/lukedewilde/",
          pic: require("@/assets/Advisors/luke.jpeg"),
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>


<style lang="scss" scoped>
p {
  padding-top: 10px;
  font-size: 1.5rem;
  color: black;
  font-weight: normal;
  overflow-wrap: break-word;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #636eff;
  margin-bottom: 40px;
  margin-left: 15%;
  width: 100%;
  display: block;
}

.content {
  animation: slideOutDown 0.8s ease-in-out forwards 0s;
  --navbarHeight: 80px; //80px is the height of navbar
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  height: auto;
  min-width: 300px;
  padding: 5% 10% 5% 10%;
  display: block;

  overflow: hidden;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10%;

  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255, 0.6)),
    to(rgba(255, 255, 255, 0.3))
  );
  background-attachment: scroll;
  border-radius: 50px;
  border: 1px solid white;

  .members {
    text-align: center;
  }

  .member {
    background-color: white;
    height: 240px;
    width: 180px;
    overflow: hidden;
    border-radius: 20px;
    margin: 40px;
    text-align: center;
    padding: 2rem;
    display: inline-block;
    position: relative;

    .name {
      margin-top: 10px;
      font-size: 1.4em;
      font-weight: 600;
    }

    .title {
      margin-top: 10px;
      color: #444444;
      font-weight: 500;
      margin-bottom: 50px;
    }

    .linkedin {
      position: absolute;
      bottom: 10px;
      text-align: center;
    }

    img {
      max-height: 100px;
      border-radius: 50%;
    }
  }

  // @media screen and (max-width: 1900px) {
  //   padding-left: 2%;
  //   padding-right: 2%;
  // }

  @media screen and (max-width: 550px) {
    margin-left: 5% !important;
    margin-right: 5% !important;
    width: 90% !important;
  }

  @media screen and (max-width: 400px) {
    margin-left: 5% !important;
    margin-right: 5% !important;
    width: 90% !important;
    padding: 0;
  }
}

section {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 160px;

  // @media screen and (max-width: 1550px) {
  //   width: 70%;
  // }

  // @media screen and (max-width: 1350px) {
  //   width: 80%;
  // }

  // @media screen and (max-width: 1175px) {
  //   width: 60%;
  // }

  // @media screen and (max-width: 750px) {
  //   width: 90%;
  // }

  // @media screen and (max-width: 550px) {
  //   margin-left: 0 !important;
  // }
}
</style>
