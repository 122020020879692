
<template>
  <section class="section blog" id="blog">
    <div class="container">
      <header class="section-heading">
        <h2 class="title">Clear Contracts Blog</h2>
      </header>
      <div class="section-content" id="blogContent">
        <div class="row blog" id="jsonContent">
          <div v-for="post in posts" v-bind:key="post.id">
            <div>
              <div
                class="blog-post"
                style="font-size: 1.2rem; margin-top: 100px"
              >
                <header>
                  <div class="blog-content">
                    <h4 style="font-size: 2rem">
                      <a
                        style="text-decoration: none; color: #636eff"
                        v-bind:href="post.guid"
                        target="_blank"
                      >
                        {{ post.title }}
                      </a>
                    </h4>
                    <div class="post-meta">
                      <span style="font-weight: bold" class="date">
                        {{ post.pubDate }}
                      </span>
                      <span class="author" style="margin-left: 20px">
                        {{ post.author }}
                      </span>
                    </div>
                    <div class="blog-element">
                      <img
                        class="img-responsive"
                        v-bind:src="post.src"
                        width="360px"
                        height="240px"
                      />
                    </div>
                  </div>
                </header>
                {{ post.description.substr(0, 280) }}...
                <div
                  style="height: 10px; border-bottom: 1px solid #d3d3d3"
                ></div>
                <a
                  style="text-decoration: none; color: #636eff"
                  v-bind:href="post.guid"
                  target="_blank"
                  >+ Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      posts: [],
    };
  },

  methods: {
    async getData() {
      const sources = ["justinschreiner", "logan_66459"];

      for (const s of sources) {
        try {
          const response = await axios.get(
            `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${s}`
          );

          for (var item of response.data.items) {
            var tagIndex = item.description.indexOf("<img"); // Find where the img tag starts
            var srcIndex =
              item.description.substring(tagIndex).indexOf("src=") + tagIndex; // Find where the src attribute starts
            var srcStart = srcIndex + 5; // Find where the actual image URL starts; 5 for the length of 'src="'
            var srcEnd =
              item.description.substring(srcStart).indexOf('"') + srcStart; // Find where the URL ends
            var src = item.description.substring(srcStart, srcEnd); // Extract just the URL

            var yourString = item.description.replace(/<img[^>]*>/g, ""); //replace with your string.
            var descStart = yourString.indexOf("</figure><p>") + 12;
            var maxLength = 280; // maximum number of characters to extract
            var descEnd = descStart + maxLength;

            //trim the string to the maximum length
            var trimmedString = yourString.substr(descStart, descEnd);
            //re-trim if we are in the middle of a word
            trimmedString = trimmedString.substr(
              0,
              Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
            );

            var desc = trimmedString.replace(/<\/?[^>]+(>|$)/g, "");
            item.src = src;
            item.description = desc;

            const date = `${item.pubDate.substr(5, 2)}/${item.pubDate.substr(
              8,
              2
            )}/${item.pubDate.substr(0, 4)}`;
            item.pubDate = date;

            this.posts.push(item);
            this.posts.sort((a, b) => (a.pubDate > b.pubDate ? -1 : 1));
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },

  created() {
    this.getData();
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
section {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 160px;
  @media screen and (max-width: 750px) {
    width: 90%;
  }
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #636eff;
  margin-bottom: 40px;
}

.author {
  font-weight: 500;
}

.blog-content {
  h4 {
    color: #636eff;

    a {
      text-decoration: none;
    }
  }
}

.section-content {
  --navbarHeight: 80px; //80px is the height of navbar

  position: relative;
  padding: 0 80px 100px 80px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(255, 255, 255, 0.6)),
    to(rgba(255, 255, 255, 0.3))
  );
  background-attachment: scroll;
  border-radius: 50px;
  border: 1px solid white;

  @media (max-width: 500px) {
    padding: 0 15px 100px 15px;
  }
}

img {
  width: 360px;
  height: 240px;

  @media (max-width: 820px) {
    display: none;
    padding: 0 20px 100px 20px;
  }
}
</style>
