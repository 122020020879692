<template>
  <div id="vue">
    <Navigation />
    <video loop muted autoplay class="background">
      <source src="./assets/gradient.mp4" type="video/mp4" />
    </video>

    <router-view v-slot="{ Component, route }">
      <!-- Use any custom transition and fallback to `fade` -->
      <div class="container">
        <transition :name="route.meta.transition || 'route'">
          <component :is="Component" :key="route.path" />
        </transition>
      </div>
    </router-view>

    <Footer id="footer" />
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import "@fontsource/rubik";

export default {
  components: {
    Navigation,
    Footer,
  },
};
</script>


<style lang="scss">
/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateX(200px);
}
.route-enter-active {
  transition: all 0.6s ease;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
.route-leave-active {
  transition: all 0.3s ease;
}

.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-200px);
}
.slide-right-enter-active {
  transition: all 0.6s ease;
}
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
.slide-right-leave-active {
  transition: all 0.3s ease;
}

// app styling
#vue {
  font-family: Rubik, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  max-width: 100%;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
}

.container {
  margin-bottom: 100px;
}

img {
  pointer-events: none;
}
#footer {
  margin-top: auto;
}

/* Moving Gradient Background */
.background {
  height: 100vh;
  z-index: -5 !important;
  position: fixed;
}
</style>
